import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeftLong, faChevronLeft} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";

export default function GoBack({to = '/', text = 'Home'}) {
    return <>
        <Link to={to}
              className={'hover:font-blasck hover:-translate-x-0.5 transition-all group w-fit block ml-2 text-xl relative'}>
            <FontAwesomeIcon icon={faChevronLeft}
                             className={'group-hover:text-2xl transition-all -ml-5 mt-1.5 group-hover:mt-0.5 group-hover:-ml-6 text-base mr-2 absolute'}/>
            {text}
        </Link>
    </>
}