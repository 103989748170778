import React, {useEffect, useRef, useState} from 'react';
import {Link} from "react-router-dom";
import {api} from "../index";
import Sh from "./SH";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";

export default function SectionNews() {

    const ref = useRef(null);
    const blocksRef = useRef([]);
    const [isLoading, setIsLoading] = useState(true);
    const [loadedNews, setLoadedNews] = useState([]);

    useEffect(() => {
        setIsLoading(true)
        api.get('/news/get').then(response => {
            setLoadedNews(Object.values(response.data.data))
        }).catch(error => {

        }).finally(() => {
            setIsLoading(false)
        })
    }, []);

    const NewsBlock = React.memo(({article, index}) =>
        index === 0 ? <NewsHeroBlock article={article}/>
            : <Link to={'/news/' + article.slug}
                    className={'overflow-hidden h-[25vh] relative transition-all opacity-50 hover:opacity-100 text-white ' +
                        'hover:text-black p-5 bg-neutral-800 rounded-3xl group hover:bg-white/10 ' +
                        'hover:text-white flex flex-wrap items-start'}>

                <img src={'/65ae6fcee5d1a.png'} className={'absolute left-0 top-0 w-full object-cover h-full'} alt={'.'}/>

                <div className={'w-full z-20 flex h-full flex-wrap'}>
                    <h2 className={'bg-black/20 h-min w-full text-6xl font-thin'}>{article.title}</h2>
                    <p>{article.short_description}</p>
                </div>

            </Link>, [])

    const NewsHeroBlock = ({article}) => <div className={'p-7 h-[50vh] w-full bg-red-950'}>
        {article.description}
    </div>


    return <div ref={ref} className={'w-full pt-52 pb-40 container mx-auto'}>
        <Sh parentRef={ref}>Latest news</Sh>
        <div style={{transform: 'translateY(' + ((-window.scrollY) * .07 + (window.innerHeight / 2.3)) + 'px)'}}>
            <div className="flex flex-wrap items-stretch">
                {loadedNews.map((n, i) =>
                    <Link to={'/news/' + n.slug}
                          className={'p-5 w-1/3 mb-10 hover:shadow-sm cursor-pointer transition-all'}>
                        <div className={'text-start rounded overflow-hidden hover:bg-sky-500/5 transition-all border-b pb-10 border-b-white/10 h-full'}>
                            <img src={'https://picsum.photos/2000?r' + Math.random()} alt={'.'}
                                 className={'object-cover mb-10 opacity-90 grayscale hover:grayscale-0 hover:opacity-100 transition-all bg-black/10 block w-full aspect-video'}/>
                            <Sh spanClassName={'inline-block !text-4xl'}>
                                {n.title}
                            </Sh>
                            <p className={'ml-12 -mt-10'}>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias atque beatae eius
                                excepturi nam omnis perferendis quas sint? Assumenda consequuntur, debitis dolorum inventore nam repudiandae
                                voluptas. Culpa neque odit velit.</p>
                        </div>
                    </Link>
                )}
            </div>
        </div>
        <div className={'flex items-center mt-52 gap-x-2 justify-end'}>
            <FontAwesomeIcon icon={faChevronLeft}/>
            <div>1</div>
            <strong>of</strong>
            <div>32</div>
            <FontAwesomeIcon icon={faChevronRight}/>
        </div>
    </div>
}