import React, {useEffect, useRef, useState} from 'react';
import {Link} from "react-router-dom";
import Sh from "./SH";
import {api} from "../index";
import Loader from "./Loader";

export const CareerBlock = ({career}) => <>
    <Link
        to={'/careers/' + career.id}
        className={'border-blue-400 py-5 px-5 rounded-xl flex items-center flex-wrap justify-between hover:scale-[1.02] hover:dark:bg-white/10 duration-300 h-full ' +
            'cursor-pointer group hover:shadow-xl hover:text-white dark:text-white ' +
            'text-neutral-600 hover:bg-slate-800'}>
        <h3 className={'font-semibold text-4xl w-full'}>{career.title}</h3>
    </Link>
</>

export default function SectionCareers() {
    const [careersList, setCareersList] = useState([]);
    const [loading, setLoading] = useState(false);
    const ref = useRef();

    useEffect(() => {
        setLoading(true)
        api.get('/careers/front').then(r => {

            if (!r?.data?.data) {
                return
            }

            console.log(r.data)
            setCareersList(r.data?.data)

            if (r.data.title) {
                setCareersList(r.data?.data)
            }

            if (r.data.description) {
                //setWorkSectionDescription(r.data.description)
            }

        }).finally(() => setLoading(false))

        const updateScrollY = () => {
//            setScrollY(window.scrollY)
        }

        window.addEventListener('scroll', updateScrollY)
        return () => window.removeEventListener('scroll', updateScrollY)
    }, []);

    const bannerRef = useRef()

    return <div ref={ref} className={'flex bg-primary-900 py-40'}>
        <div className={'mx-auto w-full relative z-10'}>
            <div className={'container mx-auto'}>

                <div className={'w-full text-white'}>
                    <div className={'flex flex-wrap items-center 2xl:justify-between'}>
                        <Sh parentRef={ref}>Join our team</Sh>
                        <div className={'w-full 2xl:w-4/5'}>
                            <div className={'h-36 rounded bg-left lg:h-52 4xl:h-72 block duration-2000 ease-out'}
                                 ref={bannerRef}
                                 style={{
                                     backgroundImage: "url(/ThinkBigWowability.gif)",
                                     backgroundAttachment: '',
                                     backgroundPositionY:
                                         Math.max(bannerRef.current?.getBoundingClientRect().bottom-2000,
                                             bannerRef.current?.getBoundingClientRect().y-600),
                                     backgroundSize: 'cover',
                                     backgroundRepeat: 'no-repeat',
                                 }}>
                            </div>
                        </div>
                    </div>
                    <div className={'flex mt-32 items-center'}>
                        <div className={'max-w-7xl text-2xl font-light px-10'}>
                            Join a team where creativity knows no bounds!<br/><br/>At our agency, we're driven by bold ideas and diverse
                            perspectives, crafting impactful solutions across industries. Here, you'll find a dynamic environment that
                            values innovation, teamwork, and growth.<br/><br/> If you're ready to push the boundaries, work on exciting
                            projects, and
                            make a real impact, we'de love ot have you on board. Let's create something extraordinary together!
                        </div>
                    </div>
                </div>

                <div className={'px-5 mt-24'}>
                    <div
                        className={'items-stretch flex flex-wrap'}>{
                        loading
                            ? <Loader/>
                            : careersList?.map(career =>
                                <div key={career.title + career.description}
                                     className={'w-full lg:w-1/4 max-w-lg'}>
                                    <CareerBlock ik={career.title} career={career}/>
                                </div>)
                    }
                    </div>
                </div>
            </div>
        </div>
        <div id={'stripes'} className={'absolute w-screen !h-full z-0 top-0 left-0 opacity-[.05]'}/>
    </div>
}
