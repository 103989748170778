import React, {useRef} from 'react';
import Sh from "./SH";

export default function SectionTeam() {

    const team = [
        {name: 'Roger Sahyoun', position: 'Chairman', thumb: 'rs.jpg'},
        {name: 'Dany Azzi', position: "Regional Executive Creative Director", thumb: '/da.jpg'},
        {name: 'Joy Sahyoun', position: 'Regional General Manager', thumb: '/js.jpg'},
        {name: 'Tarek Mansour', position: 'General Manager', thumb: '/tm.jpg'},
        {name: 'Rita Aoun.', position: 'Regional Finance Manager', thumb: 'rs.jpg'},
        {name: 'Guilda Sahyoun', position: 'Regional HR Manager', thumb: '/gs.jpg'},

    ];

    const [selected, setSelected] = React.useState(team[0]);
    const ref = useRef()

    return <div ref={ref} className={'overflow-shidden min-h-[72.5vh] py-40'}>
        <div className={'container mx-auto mb-20'}>
            <Sh parentRef={ref}>Our team</Sh>
        </div>
        <div className={'w-full mt-44 relative z-10'}>
            <div className={'flex items-center transition-all justify-center w-full'}>
                {team.map(t =>
                    <div className={'px-2 min-h-[200px] h-[30vh]'}>
                        <div className={'text-white group h-full aspect-[.57] relative duration-1000 cursor-pointer ' +
                            (selected.name === t.name
                                    ? ' opacity-100 z-20 grayscale-0 -mt-10 scale-[1.5] mx-20 mr-14'
                                    : 'scale-[.98] opacity-70 grayscale'
                            )}
                             onClick={() => setSelected(t)}
                             onMouseEnter={() => setSelected(t)}>
                            <div className={'transition-all  bg-white inline-block w-full overflowd-hidden  h-full -skew-x-[14deg] ' +
                                (selected.name === t.name ? ' shadow-xl ' : ' ')}>
                                <img srsc={t.thumb} className={'skew-x-[14deg] h-full scale-[1.45] duration-1000 object-cover ' +
                                    (selected.name === t.name ? ' scale-100'  :' ')}/></div>
                            <div className={'duration-300 absolute text-white  -mt-2  ' +
                                (selected.name === t.name ? '-ml-16 mt-1 opacity-100' : 'opacity-0')}>
                                <h3 className={'text-xl '}>
                                    {t.name}
                                </h3>
                                <span className={'text-base inline-block w-[200px]'} style={{lineHeight:1.2}}>{t.position}</span></div>
                        </div>
                    </div>)}
            </div>
        </div>
        <div id={'stripes'} className={'absolute w-screen !h-full z-0 top-0 left-0 opacity-[.05]'}/>
    </div>
}