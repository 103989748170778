import React, {useState} from 'react';
import axios from "axios";
import MapComponent from "./MapComponent";

export default function SectionContact() {

    const [isLoading, setIsLoading] = useState(false)
    const [errors, setErrors] = useState({
        name: null,
        email: null,
        message: null,
        response: null,
    })

    const [responseText, setResponseText] = useState('')
    const [data, setData] = useState({
        name: '',
        email: '',
        message: ''
    })

    const contactObjects = [{
        title: 'Beirut',
        phone: '+961 1 496286',
        body: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit.\nDoloribus eligendi inventore non quam?\n\nAb, animi aspernatur culpa cum eaque fugit itaque molestiae necessitatibus quasi quia quidem ratione recusandae voluptate voluptatum.',
        style: 'bg-cyan-100/10',
    }, {
        title: 'Dubai',
        phone: '+971 4 445 8383',
        body: 'fugit itaque molestiae necessitatibus quasi quia quidem ratione recusandae voluptate voluptatum.',
        style: 'bg-cyan-100/15',
    }, {
        title: 'Jeddah',
        phone: '+966 12 606 2207',
        body: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloribus eligendi inventore non quam? Ab, animi aspernatur culpa cum eaque fugit itaque molestiae necessitatibus quasi quia quidem ratione recusandae voluptate voluptatum.',
        style: 'bg-cyan-100/20',
    }, {
        title: 'Riyadh',
        phone: '+966 11 490 3758',
        body: "Lorem ipsum doxulpa cum eaque fugit itaque molestiae necessitatibus quasi quia quidem ratione recusandae voluptate voluptatum.",
        style: 'bg-cyan-100/25',
    }]

    const onChange = (e) => setData({...data, [e.target.name]: e.target.value})

    const submit = (e) => {
        e.preventDefault()
        setErrors([])
        setResponseText('')
        setIsLoading(true)

        let _err = errors;

        if (!data.name.length) {
            _err.name = 'Name is Required'
        }

        if (!data.email.length) {
            _err.email = ('E-mail is Required')
        }

        if (!data.message.length) {
            _err.message = ('A message is Required')
        }

        if (_err.length) {
            setErrors(_err)
            setIsLoading(false)
            return false
        }

        setIsLoading(true)


        axios.post(process.env.REACT_APP_API_URL + '/api/contact', {}).then(d => {
            setResponseText(d.data)
        }).catch(c => {

        }).finally(() => setIsLoading(false))
    }
    const ContactBlock = ({contact}) => <div className={'mx-auto hover:bg-blue-700 transition-all hover:text-white cursor-pointer'}>
        <span className={'xl:text-end font-black text-5xl'}>{contact.title}</span>
        <span className={'mb-10 font-thin block dark:text-white text-2xl'}>{contact.phone}</span>
    </div>


    return <div className={'w-full'}>
        <div className="h-full bg-black">
            <MapComponent />
        </div>
    </div>
}
