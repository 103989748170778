import React, {useEffect, useRef, useState} from 'react';
import 'react-alice-carousel/lib/alice-carousel.css';
import Sh from "./SH";
import Carousel from "./Carousel";

export default function SectionClients() {

    const ref = useRef();

    const images = [
        {name: 'Al Fardan Exchange', thumb: '/c/Al fardan exchange-01.svg'},
        {name: 'Anker', thumb: '/c/Anker.svg'},
        {name: 'Bank of Sharjah', thumb: '/c/Bank of Sharjah Logo-01.svg'},
        {name: 'BBAC', thumb: '/c/bbac.png'},
        {name: 'Bosch Logo', thumb: '/c/Bosch Logo 23.svg'},
        {name: 'DCCI', thumb: '/c/DCCI logo.svg'},
        {name: 'Dunlop', thumb: '/c/Dunlop-01.svg'},
        {name: 'Falken', thumb: '/c/Falken Logo-01.svg'},
        {name: 'Fidelity', thumb: '/c/fidelity.svg'},
        {name: 'GMG', thumb: '/c/gmg.svg'},
        {name: 'Hasbro', thumb: '/c/Hasbro.svg'},
        {name: 'Iffco', thumb: '/c/Iffco Logo-01.svg'},
        {name: 'Lacnor', thumb: '/c/Lacnor Logo.svg'},
        {name: 'Mentos', thumb: '/c/mentos-logo-01.svg'},
        {name: 'Michael Kors', thumb: '/c/michael_kors-01.svg'},
        {name: 'NorthwestBus', thumb: '/c/Northwestbuse Logo.svg'},
        {name: 'Protector and Gamble', thumb: '/c/Procter_and_Gamble-01.svg'},
        {name: 'RedBull', thumb: '/c/redbull-01.svg'},
        {name: 'Saudi Electricity Company', thumb: '/c/Saudi_Electricity_Company-01.svg'},
        {name: 'Siemens', thumb: '/c/siemens.svg'},
        {name: 'UAE Pro League', thumb: '/c/jkd2g51643234984.png'},
    ].map((image) => ({
        id: crypto.randomUUID(),
        image
    }));

    return <div ref={ref} className={'relative'}>
        <div
            className={'text-white container z-10 relative mx-auto flex 2xl:items-start items-center flex-wrap'}>
            <Sh parentRef={ref}>Our clients</Sh>
            <div className={'w-full lg:px-0 px-5 xl:px-10'}>
                <div className={'carousel mt-20'}>
                    <Carousel images={images}/>
                </div>
            </div>
        </div>
    </div>
}
