import React, {useEffect, useMemo, useRef, useState} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './pages/App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Outlet, Route, Routes} from "react-router-dom";
import NoMatch from "./pages/NoMatch";
import {AppContext} from "./context/AppContext";
import Navbar from "./components/Navbar";
import CareersIndex from "./pages/Career/CareersIndex";
import WorkIndex from "./pages/Work/WorkIndex";
import WorkSingle from "./pages/Work/WorkSingle";
import CareersSingle from "./pages/Career/CareerSingle";
import axios from "axios";
import NewsSingle from "./pages/News/NewsSingle";
import SectionFooter from "./components/SectionFooter";
import ReactLenis from "@studio-freight/react-lenis";

const root = ReactDOM.createRoot(document.getElementById('root'));

export const api = axios.create()
api.defaults.baseURL = process.env.REACT_APP_API_URL + '/api'
api.interceptors.response.use(response => response, error => {
    console.error('Err ' + error)
})

const Wrapper = () => {
    const [sidebarHidden, setSidebarHidden] = useState(false)
    const [showNavbar, setShowNavbar] = useState(true);
    const [scrollY, setScrollY] = useState(0);
    const footerRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            console.log(entries[0].intersectionRatio)
        }, {threshold: [0, .1, .2, .3, .4, .5, .6, .7, .8, .9, 1]});

        observer.observe(footerRef.current)
        return () => observer.disconnect();
    }, []);

    useEffect(() => {
        const _ = () => {
            const scrollPercent = ((window.scrollY) / ((document.documentElement.scrollHeight) -
                window.innerHeight
            )) * 100;
            setScrollPercent(scrollPercent);
            setScrollY(window.scrollY)
        }

        window.addEventListener('scroll', _)
    }, [])

    const fn = {
        sidebarHidden: sidebarHidden,
        setSidebarHidden: setSidebarHidden,
        showNavbar: showNavbar,
        setShowNavbar: setShowNavbar,
    }

    const navBarMemo = useMemo(() => <Navbar/>, [])

    const lenisOptions = {
        lerp: .1,
        duration: 3,
        smoothTouch: true, //smooth scroll for touch devices
        smooth: true,
    };


    const [scrollPercent, setScrollPercent] = useState(0);

    return <>
        <AppContext.Provider value={{...fn}}>
            <div className={''}>
                {navBarMemo}
                <ReactLenis options={lenisOptions} root>
                    <div id={'main'} className={'mb-[100vh] shadow-sm relative z-20'}>
                        <Outlet/>
                        <div id="contact"></div>
                    </div>
                </ReactLenis>
                <div ref={footerRef} className={'fixed top-28 -mt-3 z-0 w-screen'}
                     style={{
                         opacity: (scrollPercent / 3),
                         transform: 'translateY(' + (
                             -((window.scrollY) / ((document.documentElement.scrollHeight) - (window.innerHeight))) * 100
                         ) + 'px)'
                     }}
                >
                    <SectionFooter/>
                </div>
            </div>
        </AppContext.Provider>
    </>
}
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <AppContext.Provider value={{}}>
                <Routes>
                    <Route path={'/'} element={<Wrapper/>}>
                        <Route index element={<App/>}/>
                        <Route path={'work'} element={<WorkIndex/>}/>
                        <Route path={'work/:id'} element={<WorkSingle/>}/>
                        <Route path={'news/:slug'} element={<NewsSingle/>}/>
                        <Route path={'careers'} element={<CareersIndex/>}/>
                        <Route path={'careers/:id'} element={<CareersSingle/>}/>
                        <Route path="*" element={<NoMatch/>}/>
                    </Route>
                </Routes>
            </AppContext.Provider>
        </BrowserRouter>
    </React.StrictMode>
);
reportWebVitals();
