import React, {useEffect, useRef, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import {api} from "../../index";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDoubleRight, faArrowLeftLong, faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import PageContainer from "../../components/PageContainer";
import MediaGallery from "../../components/MediaGallery";
import {Helmet} from "react-helmet";
import {BarLoader} from "react-spinners";
import GoBack from "../../components/GoBack";

export default function WorkSingle() {

    const containerRef = useRef()
    const {id} = useParams();

    const [loadedProject, setLoadedProject] = React.useState({
        id: null,
        title: null,
        description: <></>,
        media: [],
    });

    const [coverImage, setCoverImage] = useState({});
    const [loadedSuccessfully, setLoadedSuccessfully] = useState(true);
    const [loading, setLoading] = React.useState(true);

    const [scrollY, setScrollY] = useState(window.scrollY);

    useEffect(() => {
        setTimeout(() => window.scrollTo({top: 0, behavior: 'smooth'}), 50)
        const _ = () => {
            setScrollY(window.scrollY);
        }
        window.addEventListener("scroll", _)
        window.addEventListener("resize", _)
        return () => {
            window.removeEventListener("scroll", _)
            window.removeEventListener("resize", _)
        }
    }, []);


    useEffect(() => {


        setLoading(true)

        api.get('/work/front/' + id).then(response => {
            if (response.data) {
                setLoadedProject(response.data);

                setTimeout(() => {
                        setCoverImage(Object.values(response.data.media).filter(o => o.is_primary)[0] || response.data.media[0])
                        window.scrollTo({top: 0, behavior: 'smooth'})
                    },
                    100
                )

                setLoadedSuccessfully(true)
            } else {
                setLoadedSuccessfully(false)
            }
        }).catch(e => {
            setLoadedSuccessfully(false)
        }).finally(() => setLoading(false))

    }, [id]);


    return <div className={'relative bg-black -mt-52 pt-60 mb-[100vh]'}>
        {loading
            ? <div className={'relative z-10 flex items-center justify-center h-[calc(100vh-250px)]'}>
                <BarLoader color={'#09e'}/>
            </div>

            : loadedSuccessfully
                ? <PageContainer title={loadedProject.title}>

                    <div style={{
                        transform: "translateY(" + Math.max(0, ((window.scrollY * .43) - (window.innerHeight / 200))) + "px)",
                    }} className={'container mx-auto pl-5 xl:mt-52 hover:pl-4  z-30 relative'}>
                        <GoBack/>
                    </div>

                    <div className={'container mx-auto z-20 relative mix-blend-color-dodge'} ref={containerRef}>
                        <div className={'container mx-auto px-5 pr-10 relative '} style={{
                            transform: "translateY(" + Math.max(0, ((window.scrollY * .44) - (window.innerHeight / 200))) + "px)",
                        }}>

                            <h1 className={'p-title'}
                            >
                                {loadedProject.title}
                            </h1>
                        </div>

                    </div>

                    <div className={'container mx-auto'}>
                        <div
                            className={'overflow-hidden max-w-7xl px-[18px] selection:bg-black durations-2000 ease-out relative z-10'}
                            style={{
                                transform: "translateY(" + Math.max(0, ((window.scrollY * .43) - (window.innerHeight / 200))) + "px)",
                            }}>
                            <article className={'text-sm relative px-3 z-20 text-white'}
                                     dangerouslySetInnerHTML={{__html: loadedProject.content}}/>
                        </div>
                        <Link to={''}
                              className={'mix-blend-difference block text-end text-4xl font-thin transition-all opacity-20 hover:opacity-100 group'}>
                            <>Nexxt Project</>
                            <FontAwesomeIcon className={'text-4xl duration-100 font-light group-hover:-mr-2 group-hover:ml-2'}
                                             icon={faAngleDoubleRight}/>
                        </Link></div>

                    <div className={''}>
                        <div className={'relative z-20'}>
                            {loadedProject.media?.length &&
                                <MediaGallery setCoverImage={setCoverImage}
                                              initMedia={loadedProject.media}
                                />
                            }
                        </div>

                        {coverImage && <img src={coverImage?.path?.full}
                                            onLoad={() => setLoading(false)}
                                            alt={'.'}
                                            className={'z-0 -top-0 h-screen object-cover fixed scale-[1.6] left-0 duration-2000 ease-out w-screen  blur-md opacity-70 -indent-96'}
                                            style={{marginTop: +(-Math.max(0, (window.scrollY * .2))) + "px"}}/>}
                    </div>
                </PageContainer>
                : <div className={'select-none flex items-center justify-center min-h-[80vh]'}>
                    <FontAwesomeIcon icon={faExclamationTriangle}/>
                    <small className={'mt-1 mx-2'}>Could not load this item</small>
                </div>
        }
    </div>
}