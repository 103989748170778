import React, {useEffect, useRef, useState} from 'react';
import {Transition} from "@headlessui/react";
import Gtext from "./Gtext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons";

export default function SectionIntroduction() {

    const videoRef = useRef()
    const [opacity, setOpacity] = useState(1);
    const [loaded, setLoaded] = useState(false);
    const [leadingClass, setLeadingClass] = useState('leading-[1.1] sm:leading-[.9] md:leading-[1] xl:leading-[1.15] 2xl:leading-[1.3] 4xl:leading-[1.2]')
    const [mainClass, setMainClass] = useState('bg-sky-950');
    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.src = '/showreel.mp4'
            const playPromise = videoRef.current?.play();
            if (playPromise !== undefined) {
                playPromise.then(_ => {
                    videoRef.current.playbackRate = .7
                }).catch(() => {
                });
            }
        }
        setTimeout(() => {
            setLoaded(true)
        }, 100)

        setTimeout(() => {
            setLeadingClass('leading-[.7] sm:leading-[.7] md:leading-[.65] xl:leading-[.65] 2xl:leading-[.64] 4xl:leading-[.6]')
        }, 300)

        setTimeout(() => {
            setMainClass('bg-sky-400/30')
        }, 1000)

    }, []);

    return <div
        style={{
            transform: "translateY(" + window.scrollY * .5 + "px)"
        }}
        className={'min-h-screen duration-1000 transition-colors flex items-center w-full overflow-hidden ' + mainClass}>
        <div className={'relative 4xl:px-40 mr-10 px-4 w-full z-30'}>
            <div className={'h-full z-30 flex items-center'}>
                <div className={'absolute w-full px-3 lg:px-10 drop-shadow'}>
                    <div className={'mt-52 text-start font-light block text-white px-3 xl:px-0'}>
                        <Transition show={!!loaded}
                                    className={'duration-1000 relative'}
                                    enterFrom={'top-[10vh] opacity-0'}
                                    enterTo={'top-0 opacity-100'}
                                    leaveFrom={'top-0 opacity-100'}
                                    leaveTo={'top-[10vh] opacity-0'}
                        >
                            <h2 className={'max-w-[70vw] 4xl:max-w-[70vw] text-[9vw] sm:text-6xl md:text-7xl xl:text-8xl 2xl:text-9xl 4xl:text-9xl ' + leadingClass}>
                                <div className={'4xl:max-w-[55vw]'}>
                                    <Gtext/>
                                </div>
                            </h2>
                        </Transition>
                        <div
                            className={'my-10 duration-2000 border-0 h-[3px] !max-w-[60vw] glow rounded ease-out bg-sky-400/80'}
                            style={{
                                width: Math.max(0, ((-(-window.scrollY + (window.innerHeight) / 20) * 2)) * .03) + '%',
                                maxWidth: '100%',
                            }}/>
                        <Transition show={!!loaded}
                                    className={'duration-500  relative delay-500'}
                                    enterFrom={'top-[10vh] opacity-0'}
                                    enterTo={'top-0 opacity-100'}
                                    leaveFrom={'top-0 opacity-100'}
                                    leaveTo={'top-[10vh] opacity-0'}>
                            <p className={'my-10 text-justify 2xl:max-w-7xl text-[100%]] md:text-lg xl:text-2xl 4xl:text-3xl'}>
                                We're all about fresh ideas, and we’re not shy about chasing them down. To us, the universe is made of
                                stories — and
                                we’re here to help yours get noticed. Working side by side with our brand partners, we create campaigns
                                thats
                                don’t
                                just connect with audiences but make them lean in and stay a while.
                            </p>
                        </Transition>
                    </div>
                </div>
            </div>
        </div>
        <FontAwesomeIcon icon={faChevronDown}
                         className={'absolute bottom-20 text-4xl text-sky-200 w-screen text-center animate-extraSlowBounce'}/>
        <div id="stripes" className={'absolute z-20 opacity-[.2] mix-blend-luminosity invert h-screen w-screen -top-0 left-0'}/>
        <div className={'w-screen z-10 absolute top-0 left-0 h-screen'}></div>
        <video ref={videoRef}
               className={'absolute top-0 z-0 h-screen w-screen object-cover opacity-30 invert  scale-125 blur-xl'} autoPlay
               loop
               muted/>
    </div>
}
