import React, {useEffect, useRef, useState} from 'react';
import {Link} from "react-router-dom";
import {api} from "../index";
import html2canvas from 'html2canvas';
import Loader from "./Loader";
import Sh from "./SH";

export const ProjectBlock = ({className, project, link}) => {


    const linkClassName = 'group block z-0 relative duration-500 ' +
        'relative hover:z-1  ' + className + (link ? ' hover:z-10 cursor-pointer' : ' cursor-default');

    return <>
        <Link to={link} className={linkClassName}>
            <div
                className={'aspect-video h-full'}>
                <div
                    className={'w-full grayscale hover:grayscale-0 h-full shadow-xl overflow-hidden absolute top-0 bg-black/5 left-0 flex items-center justify-center'}>
                    {project.photo ?
                        <img src={project.photo?.wide_md ?? '/placeholder.svg'}
                             className={(link ? 'group-hover:scale-[1.1]' : '') + ' duration-200 ease-in absolute flex items-center justify-center text-black ' +
                                 'top-0 left-0 w-full bg-black h-neutral-800 h-full object-cover '}
                             style={{textIndent: -999999}}
                             alt={project?.title ?? '-'}/>
                        : <img src={'/static/placeholder.svg'} className={'w-10 mx-auto dark:invert opacity-[5%]'}
                               alt={project?.title ?? '-'}/>}
                </div>
            </div>

            <div
                className={'flex flex-wrap duration-300 px-5 left-0 absolute bottom-0 z-10 text-sm mix-blend-exclusion drop-shadow py-2'}>
                <span className={'w-full drop-shadow-lg transition-all block'}>
                    {project.title}
                </span>
            </div>
        </Link>
    </>
}


export default function SectionOurWork() {
    const [workSectionTitle, setWorkSectionTitle] = useState('Our Work')
    const [workSectionDescription, setWorkSectionDescription] = useState('');
    const [work, setWork] = useState([]);
    const [loading, setLoading] = useState(false);

    const [scrollY, setScrollY] = useState(window.scrollY);

    const workRef = useRef()
    const workRefContainer = useRef()

    const [backgroundImage, setBackgroundImage] = useState(null);


    const captureElement = async () => {
        if (workRefContainer.current) {
            const canvas = await html2canvas(workRefContainer.current, {
                // Optional configuration for image rendering and CSS handling
                // ...
                allowTaint: true,
                useCORS: true,
                backgroundColor: 'transparent'
            });
            const imageDataURL = canvas.toDataURL('image/jpeg');
            setBackgroundImage(imageDataURL);
        }
    };


    useEffect(() => {
        // Load work objects
        setLoading(true)
        api.get('/work/front').then(r => {

            if (!r?.data?.data) {
                return
            }

            setWork(r.data?.data)

            if (r.data.title)
                setWorkSectionTitle(r.data.title)

            if (r.data.description)
                setWorkSectionDescription(r.data.description)


            setTimeout(captureElement, 0);

        }).finally(() => setLoading(false))

        const updateScrollY = () => {
            setScrollY(window.scrollY)
        }

        window.addEventListener('scroll', updateScrollY)
        return () => window.removeEventListener('scroll', updateScrollY)
    }, []);

    return <>
        <div className={'relative overflows-hidden group/mainContainer'} ref={workRef}>


            <div className={'w-full z-10 block'}>
                <div className={'mx-auto container mb-32 px-3'}>
                    <Sh parentRef={workRef}>Our work</Sh>
                </div>
                {loading
                    ? <Loader/>
                    : <div
                        className={'relative container mx-auto flex items-center content-center flex-wrap md:px-0'}>
                        <div className={'flex flex-wrap -left-[11vw] scale-[1.5] w-screen opacity-5 invert absolute top-[30vh] blur-sm'}>
                            {work.map((w, i) =>
                                <div key={w.title}
                                     className={'z-10 relative w-1/2 sm:w-1/2 md:w-1/2 scale-150 duration-[3600ms] ease-out lg:w-1/2 3xl:w-1/3 xl:w-1/3'}>
                                    <img className={'object-cover w-full block'} src={w.photo.square_sm} i={i}/>
                                </div>)}
                        </div>
                        <div className={'flex mx-auto flex-wrap z-30 w-full justify-center'}
                             ref={workRefContainer}>
                            {work.map((w, i) =>
                                <div key={w.title}
                                     className={'z-10 relative w-1/2 sm:w-1/2 md:w-1/2 duration-[1600ms] ease-out lg:w-1/2 3xl:w-1/3 xl:w-1/3 p-3'}
                                     style={{
                                         transform: "translateY(" +
                                             Math.max(0,
                                                 Math.min(window.innerHeight, (-((window.scrollY * 7.3) - workRef.current?.getBoundingClientRect()?.top - (window.innerHeight * 20))) / ((i % 2) + 3.7))
                                             )
                                             + "px)"
                                     }}>
                                    <ProjectBlock project={w} link={w.link} i={i}/>
                                </div>)}

                        </div>

                    </div>
                }</div>
        </div>
        <div id={'stripes'} className={'absolute w-screen !h-full z-0 top-0 left-0 opacity-[.05]'}/>
    </>
}
