import React from 'react';
import SectionFooter from "./SectionFooter";
import {Helmet} from "react-helmet";

function PageContainer({mainElement, title, children, helmet = {title: title, description: null}, heading}) {


    return <div className={''}>
        <Helmet>
            <title>{helmet.title}</title>
        </Helmet>
        <div className={''}>
            <div className={'min-h-[calc(100vh-365px)]'}>
                <div>
                    {heading}
                </div>
                {children}
            </div>
        </div>
    </div>
}

export default PageContainer;
