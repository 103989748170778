import React, {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import PageContainer from "../../components/PageContainer";
import {api} from "../../index";
import {BarLoader} from "react-spinners";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeftLong, faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import Hr from "../../components/Hr";

export default function CareersSingle() {
    const {id} = useParams();
    const [loading, setLoading] = React.useState(true);
    const [loadedSuccessfully, setLoadedSuccessfully] = useState(true);
    const [loadedCareer, setLoadedCareer] = useState({
        id: null,
        title: null,
        description: null,
    });

    useEffect(() => {

        setTimeout(() => {
            window.scrollTo({top: 0})
        }, 50)

        api.get('/careers/' + id).then(response => {
            setLoadedCareer(response?.data)
            setLoadedSuccessfully(true)
        }).catch(error => {
            setLoadedSuccessfully(false)
        }).finally(() => setLoading(false))

    }, []);

    return <div className={'p-10 text-neutral-800 bg-neutral-100'}>
        {loading
            ? <div className={'relative z-10 flex items-center justify-center h-[calc(100vh-250px)]'}>
                <BarLoader color={'#09e'}/>
            </div>

            : loadedSuccessfully
                ? <PageContainer title={(loadedCareer.data?.title ?? '')}>
                    <div className={'container mx-auto min-h-screen py-60'}>
                        <article>

                            <div style={{
                                transform: "translateY(" + Math.max(0, ((window.scrollY * .43) - (window.innerHeight / 200))) + "px)",
                            }} className={'container text-xs mx-auto pl-5 hover:pl-4 duration-100  z-30 relative'}>
                                <Link to={'/'}
                                      className={'w-fit block'}>
                                    <FontAwesomeIcon icon={faArrowLeftLong}/> Go Back
                                </Link>
                            </div>

                            <div className={'container mx-auto px-5 pr-10 relative '} style={{
                                transform: "translateY(" + Math.max(0, ((window.scrollY * .44) - (window.innerHeight / 200))) + "px)",
                            }}>

                                <h1 className={'p-title'}
                                >
                                    {loadedCareer.data.title}
                                </h1>
                            </div>

                            <Hr/>

                            <p>{loadedCareer.data.description}</p>
                            <p>{loadedCareer.data.description}</p>
                            <p>{loadedCareer.data.description}</p>
                            <p>{loadedCareer.data.description}</p>
                            <p>{loadedCareer.data.description}</p>
                            <p>{loadedCareer.data.description}</p>
                            <p>{loadedCareer.data.description}</p>
                            <p>{loadedCareer.data.description}</p>
                            <p>{loadedCareer.data.description}</p>
                            <p>{loadedCareer.data.description}</p>
                            <p>{loadedCareer.data.description}</p>
                        </article>
                    </div>
                </PageContainer> :
                <div className={'select-none flex items-center justify-center min-h-[80vh]'}>
                    <FontAwesomeIcon icon={faExclamationTriangle}/>
                    <small className={'mt-1 mx-2'}>Could not load this item</small>
                </div>
        }
    </div>
}