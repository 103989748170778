import React, {useContext, useEffect, useRef} from 'react';
import SectionOurWork from "../components/SectionOurWork";
import SectionCareers from "../components/SectionCareers";
import SectionIntroduction from "../components/SectionIntroduction";
import SectionClients from "../components/SectionClients";
import {AppContext} from "../context/AppContext";
import SectionNews from "../components/SectionNews";
import SectionShowreel from "../components/SectionShowreel";
import SectionAbout from "../components/SectionAbout";
import {Helmet} from "react-helmet";
import SectionTeam from "../components/SectionTeam";

export default function App() {

    const {setShowNavbar, showNavbar} = useContext(AppContext)
    const ref = useRef(null);
    const videoRef = useRef(null);

    useEffect(() => {
        if (window.scrollY > videoRef.current?.clientHeight) {
            setShowNavbar(true)
        }
    }, [setShowNavbar])

    return <>

        <Helmet>
            <title>{process.env.REACT_APP_TITLE}</title>
            <meta name={'description'} content={process.env.REACT_APP_DESCRIPTION}/>
        </Helmet>

        <div ref={ref} className={'bg-black'}>

            <div id="introduction"
                 className={'relative min-h-screen bg-primary-500'}>
                <SectionIntroduction/>
            </div>

            <div ref={videoRef} id={'showreel'} className={'bg-black relative z-50 min-h-screen max-h-screen'}>
                <SectionShowreel/>
            </div>

            <div id="about" className={'relative min-h-screen bg-primary-400 z-20'}>
                <SectionAbout/>
            </div>

            <div id="work" className={'min-h-screen bg-primary-500 text-white py-40 overflow-hidden relative z-30'}>
                <SectionOurWork _ref={ref}/>
            </div>

            <div id="clients"
                 className={'relative py-20 bg-primary-500'}>
                <SectionClients/>
            </div>
            <div id="team"
                 className={'bg-primary-500 relative z-10 py-32 pb-24'}>
                <SectionTeam/>
            </div>
            <div id="news"
                 className={'relative min-h-screen bg-primary-400'}>
                <SectionNews/>
            </div>

            <div id="careers"
                 className={'bg-primary-500 relative z-10'}>
                <SectionCareers/>
            </div>


        </div>
    </>
}