import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebookSquare, faInstagram, faLinkedinIn, faYoutube} from "@fortawesome/free-brands-svg-icons";
import {faCircle} from "@fortawesome/free-solid-svg-icons";
import SectionContact from "./SectionContact";

export default function SectionFooter() {

    const socialIconClass = 'text-3xl dark:opacity-50 hover:text-sky-200 hover:scale-110 dark:hover:opacity-100 hover:opacity-100 transition-all'

    const SocialBox = ({className}) => <>
        <div
            className={'flex text-center justify-center xl:justify-start gap-x-5 transition-all duration-1000 items-center text-3xl'
                + (className ? ' ' + className : '')}>
            <a href={'https://www.facebook.com/AGAADK'}
               className={socialIconClass}
               rel={'noreferrer'}
               target={'_blank'}>
                <FontAwesomeIcon icon={faFacebookSquare}/>
            </a>
            <FontAwesomeIcon className={'fonts-xs w-0.5 mt-1 opacity-30'} icon={faCircle}/>
            <a href={'https://www.instagram.com/agaadkmena'}
               className={socialIconClass}
               rel={'noreferrer'}
               target={'_blank'}>
                <FontAwesomeIcon icon={faInstagram}/>
            </a>
            <FontAwesomeIcon className={'fonts-xs w-0.5 mt-1 opacity-30'} icon={faCircle}/>
            <a href={'https://www.linkedin.com/company/aga-adk-advertising-&-marketing/'}
               className={socialIconClass}
               rel={'noreferrer'}
               target={'_blank'}>
                <FontAwesomeIcon icon={faLinkedinIn}/>
            </a>
            <FontAwesomeIcon className={'fonts-xs w-0.5 mt-1 opacity-30'} icon={faCircle}/>
            <a href={'https://www.youtube.com/@aga-adk1383'}
               className={socialIconClass}
               rel={'noreferrer'}
               target={'_blank'}>
                <FontAwesomeIcon icon={faYoutube}/>
            </a>
        </div>
    </>

    return <div className={'text-white min-h-screen place-content-between text-xs'}>
        <div className={'w-full flex min-h-[calc(100vh-340px)] overflow-auto bg-primary-300'}>
            <SectionContact/>
        </div>
        <div className={'text-center py-14 pb-32 bg-primary-500 relative'}>
            <p className={'text-lg mb-10 font-thin'}>Member of THE NETWORK COMMUNICATION GROUP</p>
            <div className={'flex sm:px-20 flex-wrap items-stretch justify-center'}>
                <div
                    className={'w-full gap-y-10 gap-x-14 flex-wrap invert max-w-4xl flex items-end justify-around'}>
                    <img className={'max-w-[300px] border-r border-neutral-500 pr-20  h-12 inline-block 4xl:h-14'} src="/tncg.svg" alt=""/>
                    <img className={'max-w-[30%] h-8 4xl:h-10'} src="/equation.svg" alt=""/>
                    <img className={'max-w-[30%] h-9 4xl:h-12'} src="/pencell.svg" alt=""/>
                    <img className={'max-w-[30%] h-8 4xl:h-10'} src="/wetpaint.svg" alt=""/>
                </div>

            </div>
            <div className={'mt-10 w-full flex items-center justify-center flex-wrap'}>
                <SocialBox/>
            </div>

            <div className={'mx-auto w-full py-8 opacity-20 justify-center'}>
                <div className={'w-full text-center mx-auto sm:px-20 whitespace-nowrap select-none'}>
                    <span>&copy; {new Date().getFullYear()} – AGA AGK</span>
                </div>
            </div>
        </div>

    </div>
}
