import React, {useEffect, useRef} from 'react';

export default function Sh({parentRef, children, h2ClassName, spanClassName, gapMultiplier = 20}) {
    const ref = useRef(null);
    const containerRef = useRef(null);
    const [inView, setInView] = React.useState(false);
    const [inViewSecond, setInViewSecond] = React.useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(e => {
                if (e.isIntersecting) {
                    setInView(true)
                } else {
                    setInView(false)
                }
            })

        }, {threshold: [0]})

        const containerObserver = new IntersectionObserver(entries => {
            entries.forEach(t => {
                if (t.isIntersecting) {
                    setInViewSecond(true)
                } else {
                    setInViewSecond(false)
                }
            })

        }, {threshold: [.4]})


        if (ref.current) {
            observer.observe(ref.current);
        }

        if (parentRef?.current) {
            containerObserver.observe(parentRef.current)

        }

        return () => observer.disconnect()

    }, [ref, parentRef]);

    return <>
        <span
            className={'delay-[50ms] delay-[100ms] delay-[150ms] delay-[200ms] delay-[250ms] delay-[300ms] delay-[350ms] delay-[400ms] ' +
                'delay-[450ms] delay-[500ms] delay-[550ms] delay-[600ms] delay-[700ms] delay-[800ms]'}></span>
        <h2 ref={ref}
            className={'sh overflow-hidden h-min py-2 inline-block break-all ease-out border-l-4 border-l-sky-500 duration-[20000] pb-6 xl:pb-2 pl-10' +
                (h2ClassName ? ' ' + h2ClassName : '')} style={{lineHeight:0}}>
            {children.replace(/<\/?[^>]+(>|$)/g, "")
                .split(' ')
                .map((word, i) =>
                    <span className={'drop-shadow text-white duration-[2000ms] -mt-1 block '
                        + (
                            i > 0
                                ? 'font-semibold text-4xl lg:text-6xl xl:text-7xl'
                                : 'font-light text-4xl lg:text-6xl xl:text-6xl'
                        ) + (spanClassName ? ' ' + spanClassName : '') +
                        (inView ? ' opacity-100' : ' opacity-10')}
                          style={{
                              transform: inView ? ('translateY(0px)') : ('translateY(' + (i * gapMultiplier) + '9px)'),
                              lineHeight: .7,
                          }}>
                        {word.split('').map((letter, a) => <>
                            <span className={'duration-1000 ease-in ' +
                                (' delay-[' + ((i + 1) * (a + 1)) * 50 + 'ms] ')}
                                  style={{
                                      color: !inViewSecond ? 'white' : '#239cd0',
                                  }}>
                                {letter.toString()}
                            </span>
                        </>)}
                        &nbsp;</span>
                )}
        </h2>
    </>
}