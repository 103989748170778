import React from 'react';

export default function Carousel({speed = 50000, images = []}) {
    return (
        <div className="inner">
            <div className="wrapper">
                <section style={{"--speed": `${speed}ms`}}>
                    {images.map(({id, image}) => (
                        <div className="image" key={id}>
                            <img src={image.thumb} alt={id}/>
                        </div>
                    ))}
                </section>
                <section style={{"--speed": `${speed}ms`}}>
                    {images.map(({id, image}) => (
                        <div className="image" key={id}>
                            <img src={image.thumb} alt={id}/>
                        </div>
                    ))}
                </section>
                <section style={{"--speed": `${speed}ms`}}>
                    {images.map(({id, image}) => (
                        <div className="image" key={id}>
                            <img src={image.thumb} alt={id}/>
                        </div>
                    ))}
                </section>
            </div>
        </div>
    );
}