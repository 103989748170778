import React, {useEffect, useRef, useState} from 'react';
import Sh from "./SH";


export const AboutBlock = ({containerRef, i = 0, className, offsetTop, title, children}) =>
    <div className={'4xl:leading-none p-10 relative 2xl:flex  ' +
        (className ? ' ' + className : '')}
         style={{
             opacity: Math.max(5, (((window.scrollY * .5) - (window.innerHeight * .76)) * .4 + (i * 50))) + '%',
         }}
    >
        <h2 className={'inline-block animate-text-4xl 2xl:text-5xl lg:text-6xl 2xl:min-w-[240px] mt-0 mb-3 select-none font-bold'}>{title}</h2>
        <p className={'inline py-2 pl-2 text-lg lg:text-xl 4xl:text-2xl'}>{children}
        </p>
    </div>

export default function SectionAbout() {
    const ref = useRef()
    const [scrollY, setScrollY] = useState(0);

    useEffect(() => {
        const _ = () => {
            setScrollY(window.scrollY)
        }

        window.addEventListener('scroll', _)
        return () => window.removeEventListener('scroll', _)
    }, []);

    return <div className={'overflow-hidden pb-[20vh] '} ref={ref}>
        <div className={'min-h-screen container mx-auto'}
             style={{transform: "translateY(" + (Math.max(0, (window.scrollY - window.innerHeight) * .26)) + "px)"}}>

            <Sh parentRef={ref}>About us</Sh>

            <div className={'w-full flex flex-wrap pb-[50vh] items-start'}>
                <div className={'xl:w-1/2  flex flex-wrap place-content-between'}
                     style={{lineHeight: Math.min(.9, Math.max(3.2, window.scrollY - window.innerHeight))}}>
                    <AboutBlock
                        containerRef={ref}
                        i={2}
                        title={'History'}
                        className={'xl:w-full w-full'}>
                        Founded in 1997, we started as AGA – Arabian Gulf Advertising & Marketing, a full-service agency, dedicated to help
                        brands create memorable campaigns in the Middle East through creative bravery, strategic planning, media solutions,
                        PR,
                        branding and physical experiences. Through perseverance and ambition, we grew out of Dubai and became present across
                        the
                        region
                        in Lebanon, Jordan, Egypt and KSA. <br/><br/>Our achievements led to us signing an affiliation agreement with ADK,
                        Japan’s
                        third
                        largest agency Network with 50 offices around the world, and thus our new journey as AGA-ADK began. <br/>
                        <br/> Today, we are
                        part of a
                        larger holding company known as The Network Communication Group - TNCG that provides holistic integrated marketing
                        communication solutions in the MENA region, all under one roof.
                    </AboutBlock>

                </div>
                <div className={'w-full xl:w-1/2 content-between flex flex-wrap items-center justify-center'}>
                    <AboutBlock
                        containerRef={ref}
                        i={1}
                        className={'w-full'}
                        title={'Vision'}>
                        We strive to deliver evermore memorable campaigns to our brand partners by bringing forward fully integrated
                        marketing communication solutions that go beyond the now.
                    </AboutBlock>
                    <AboutBlock
                        containerRef={ref}
                        i={0} className={'w-full'}
                        title={'Mission'}>
                        We exist to create meaningful connections between brands and consumers in an ever evolving world driven by mobile
                        interaction and physical experiences.
                    </AboutBlock>

                </div>
            </div>
        </div>
        <div className={'w-full z-40 absolute bottom-0 content-between flex flex-wrap items-center justify-center ' +
            'aspect-square md:aspect-auto md:bg-transparent'}>
            <img src={'/au.gif'} className={'hidden md:block w-full'} alt={'.'}/>
        </div>

    </div>
}