import React from 'react';
import {APIProvider, Map, Marker} from "@vis.gl/react-google-maps";

export default function MapComponent() {
    const key = 'AIzaSyDD6IGOEzxWiH9ay3UCRXv6AOKBU973qVA'

    return <>
        <APIProvider apiKey={key}>
            <Map
                style={{width: '100%', height: '100%'}}
                defaultZoom={3}
                maxZoom={8}
                minZoom={3}
                defaultCenter={{lat: 24.981710754632743, lng: 45.54960188777222}}
                colorScheme={'DARK'}
                gestureHandling={'greedy'}
                disableDefaultUI={true}
                scrollwheel={false}
                mapTypeId={'roadmap'}>

                <Marker position={{lat: 25.0789221, lng: 55.1489519}}/>
                <Marker position={{lat: 40.7517028, lng: -73.9780128}}/>
                <Marker position={{lat: 39.92045, lng: 116.460549}}/>
                <Marker position={{lat: 23.122854, lng: 113.3137007}}/>
                <Marker clickable={true} position={{lat: 30.4808652, lng: 114.150479}}
                onClick={()=>alert('s')}/>
                <Marker position={{lat: 36.1131789, lng: 120.4469861}}/>
                <Marker position={{lat: 22.3185439, lng: 114.1663584}}/>

                {/* Sydney */}
                <Marker position={{lat: -33.8653087, lng: 151.2052844}}/>

            </Map>
        </APIProvider>
    </>
}